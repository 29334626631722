<!-- Ce composant affiche les partenaires de la plateforme sur la page d'accueil -->
<template>
  <div class="partners-div mt-7">
    <!-- Titre -->
    <p class="partners-title">Les partenaires</p>
    <v-container>
      <v-row justify="center" align="center" no-gutters>
        <!-- Section Grand Est (voir ci-dessus pour les détails) -->
        <v-col justify="center" cols="12" sm="3" class="px-2 mb-4">
          <v-img
            :src="require('@/assets/images/logo_grandest_blank.png')"
            :lazy-src="require('@/assets/images/logo_grandest_blank.png')"
            max-height="100px"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <p class="text-center mt-2">
            <a
              href="https://www.grandest.fr/"
              target="_blank"
              rel="noopener noreferrer"
              style="color: white; text-decoration: none"
              >Région Grand Est</a
            >
          </p>
        </v-col>

        <!-- Section Préfecture Grand Est (voir ci-dessus pour les détails) -->
        <v-col justify="center" cols="12" sm="3" class="px-2 mb-4">
          <v-img
            :src="require('@/assets/images/logo_prefet.png')"
            :lazy-src="require('@/assets/images/logo_prefet.png')"
            max-height="100px"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <p class="text-center mt-2">
            <a
              href="https://www.prefectures-regions.gouv.fr/grand-est/"
              target="_blank"
              rel="noopener noreferrer"
              style="color: white; text-decoration: none"
              >Préfecture de la région Grand Est</a
            >
          </p>
        </v-col>

        <!-- Section Union Européenne (voir ci-dessus pour les détails) -->
        <v-col justify="center" cols="12" sm="3" class="px-2 mb-4">
          <v-img
            :src="require('@/assets/images/logo-ue-feder.jpg')"
            :lazy-src="require('@/assets/images/logo-ue-feder.jpg')"
            max-height="100px"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <p class="text-center mt-2">
            <a
              href="https://europa.eu/european-union/index_fr"
              target="_blank"
              rel="noopener noreferrer"
              style="color: white; text-decoration: none"
              >Union Européenne</a
            >
          </p>
        </v-col>

        <!-- Section Union Européenne (voir ci-dessus pour les détails) -->
        <v-col justify="center" cols="12" sm="3" class="px-2 mb-4">
          <v-img
            :src="require('@/assets/images/engage_europe.jpg')"
            :lazy-src="require('@/assets/images/engage_europe.jpg')"
            max-height="100px"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <p class="text-center mt-2">
            <a
              href="https://europa.eu/european-union/index_fr"
              target="_blank"
              rel="noopener noreferrer"
              style="color: white; text-decoration: none"
              >Union Européenne</a
            >
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Partners",
};
</script>