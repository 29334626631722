<!-- Ce composant est un slider contenant les cartes villes -->
<template>
  <v-container class="mt-15 mb-15">
    <p class="partners-title">Villes principales</p>
    <v-slide-group
      show-arrows
    >
      <v-slide-item
        v-for="city in Cities"
        :key="city.id"
      >
        <City :name="city.name" :id="city.id" :insee="city.insee" :surface="city.surface" :population="city.population"></City>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>

<script>
// Ce fichier JSON contient une liste de ville à afficher
import Cities from "@/assets/cities.json"
import City from "@/components/Home/City"

export default {
  name: 'Cities',
  components: {
    City
  },
  data () {
    return {
      Cities: Cities
    }
  }
}
</script>