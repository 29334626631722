<template>
  <div class="table-millesime">
    <p class="table-title">Millésimes OCSGE 2 disponibles</p>
    <v-container>
      <v-row justify="center">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="dpt"
          :style="isMobile ? '' : 'width: 66vw'"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th v-for="h in headers" :key="h.text" :class="h.class">
                  <span class="d-flex justify-center" style="color: white; font-size: 16px">{{h.text}}</span>
                  <span class="d-flex justify-center" style="color: white; font-size: 12px">{{h.subText}}</span>
                  <span class="d-flex justify-center" style="color: white; font-size: 12px">{{h.subText2}}</span>
                </th>
              </tr>
            </thead>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TableMillesime",
  data () {
    return {
      headers: [
        {
          text: 'Département',
          align: 'center',
          sortable: false,
          value: 'dpt',
          class: 'header-style'
        },
        {
          text: 'Millésime 2010',
          subText: "année d'acquisition de la PVA",
          subText2: "(Prise de vue Aérienne)",
          align: 'center',
          sortable: false,
          value: '2010',
          class: 'header-style'
        },
        {
          text: 'Millésime 2019',
          subText: "année d'acquisition de la PVA",
          subText2: "(Prise de vue Aérienne)",
          align: 'center',
          sortable: false,
          value: '2019',
          class: 'header-style'
        }
      ],
      items: [
        {
          'dpt': 'Ardennes (08)',
          '2010': '2010',
          '2019': '2019',
        },
        {
          'dpt': 'Aube (10)',
          '2010': '2009',
          '2019': '2019',
        },
        {
          'dpt': 'Marne (51)',
          '2010': '2008',
          '2019': '2019',
        },
        {
          'dpt': 'Haute-Marne (52)',
          '2010': '2010',
          '2019': '2019',
        },
        {
          'dpt': 'Meurthe-et-Moselle (54)',
          '2010': '2009',
          '2019': '2018',
        },
        {
          'dpt': 'Meuse (55)',
          '2010': '2007',
          '2019': '2018',
        },
        {
          'dpt': 'Moselle (57)',
          '2010': '2009',
          '2019': '2018',
        },
        {
          'dpt': 'Bas-Rhin (67)',
          '2010': '2007',
          '2019': '2018',
        },
        {
          'dpt': 'Haut-Rhin (67)',
          '2010': '2007',
          '2019': '2018',
        },
        {
          'dpt': 'Vosges (88)',
          '2010': '2010',
          '2019': '2018',
        }
      ]
    }
  },
  computed: {
    isMobile () {
      return window.screen.width <= 768
    }
  }
}
</script>

<style scoped>
.table-millesime {
  padding: 3% 0 3% 0;
}

.table-title {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 2%;
}

.header-style {
  background-color: #4473c5;
  color: white
}
</style>