<!-- Ce composant est une copie de la section partenaires. Seul le contenu change -->
<template>
  <div class="download-section-div">
    <p class="partners-title">Accédez aux données OCS GE2</p>
    <v-container>
      <v-row align="center" justify="center">
        <v-col :class="isMobile ? 'd-flex justify-center' : 'd-flex justify-end align-start'">
          <v-img
            :src="require('@/assets/images/data.png')"
            :lazy-src="require('@/assets/images/data.png')"
            max-width="500px"
            eager
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col>
          <p :style="isMobile ? '' : 'max-width: 530px'">
            Retrouvez sur la plateforme et en téléchargement l'ensemble des données cartographiques et statistiques de l'occupation du sol de la région
            Grand Est pour les millésimes 2010 et 2019.
          </p>
          <p>
            Les données sont également déclinées et disponible pour les :
            <ul>
              <li><b>10 départements</b></li>
              <li><b>5 121 communes</b></li>
              <li><b>149 EPCI</b></li>
              <li><b>38 SCOT</b></li>
              <li><b>6 PNR</b></li>
            </ul>
          </p>
          <v-row justify="center" align="center">
            <v-col>
              <v-btn class="ma-1" dark outlined rounded href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol#donnees" target="__blank" style="text-transform: inherit">
                Télécharger les données <v-icon class="ml-2">mdi-download</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn download href="OCSGE2_description_indicateurs_plateforme_version2_avril2022.pdf" class="ma-1" dark outlined rounded style="text-transform: inherit">Méthodes de calcul<v-icon class="ml-2">mdi-download</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DownloadSection",
  computed: {
    isMobile () {
      return window.screen.width <= 768
    }
  }
}
</script>

<style scoped>
.download-section-div {
  margin-top: 3%;
  position: relative;
  padding: 2% 0 2% 0;
  background-color: #4473c5;
  justify-content: center;
  color: white;
}
</style>