<!-- Ce composant est une carte affichant des informations pour 1 ville -->
<template>
  <v-container>
    <v-card
      class="city-card"
      style="background-color: #4473c5"
      width="310px"
      :href="'/explore/' + this.insee"
    >
      <v-img
        :src="require('@/assets/images/cities/' + this.name + '.jpg')"
        gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.6)"
        max-height="150px"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="#4473c5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-card-title
          class="white--text"
          style="position: absolute; bottom: 10px"
          ><b>{{ this.name }}</b></v-card-title
        >
        <p
          class="white--text ml-4 mb-0 pb-0"
          style="position: absolute; bottom: 5px"
        >
          <v-icon color="white">mdi-crop-free</v-icon
          ><span class="ml-2" style="position: relative; top: 2px"
            >{{ this.surface }} km²</span
          >
        </p>
        <p
          class="white--text mb-0 pb-0"
          style="position: absolute; bottom: 5px; right: 10px"
        >
          <v-icon color="white">mdi-account-group</v-icon
          ><span class="ml-2" style="position: relative; top: 2px"
            >{{ this.population }} hab</span
          >
        </p>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "City",
  // Nous récupérons les données à afficher sur la carte
  props: {
    name: {
      type: String,
      required: true,
    },
    insee: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    surface: {
      type: Number,
      required: true,
    },
    population: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.city-card {
  position: relative;
  background-color: white;
  align-self: center;
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.26);
  transition: all 0.25s ease-in-out;
}

.city-card:hover {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4) !important;
}
</style>