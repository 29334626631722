<template>
  <!-- Page d'accueil de la plateforme -->
  <div>
    <!-- Bandeau d'accueil de la plateforme (Version desktop) -->
    <v-img class="home-img d-none d-md-flex" min-height="33vh" width="100vw" :src="require('@/assets/images/header-bg.jpg')" :lazy-src="require('@/assets/images/header-bg.jpg')">
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col>
            <v-container class="d-flex justify-center align-center">
              <div style="width: fit-content">
                <h1 class="title-img">Explorez l'occupation du sol<br/>de la région Grand Est !</h1>
                <br/>
                <h2 class="title-h2-img">Le référentiel OCS GE2</h2>
                <p class="pb-7 img-text">L'OCcupation du Sol à Grande Échelle de la Région Grand Est</p>
                <HomeSearch/>
                <v-row no-gutters class="mt-5 pt-2 pb-2" style="background-color: white; border-radius: 15px" justify="center" height="100px">
                  <v-col cols="2" class="pa-0 ma-0 mr-3">
                    <v-img :src="require('@/assets/images/logo_geograndest_blank.png')" :lazy-src="require('@/assets/images/logo_geograndest_blank.png')" width="150px" height="65px" contain></v-img>
                  </v-col>
                  <v-col cols="2" class="pa-0 ma-0 mr-3">
                    <v-img :src="require('@/assets/images/logo_prefet.png')" :lazy-src="require('@/assets/images/logo_prefet.png')" width="150px" height="65px" contain></v-img>
                  </v-col>
                  <v-col cols="3" class="pa-0 ma-0">
                    <v-img :src="require('@/assets/images/logo_grandest_blank.png')" :lazy-src="require('@/assets/images/logo_grandest_blank.png')" width="160px" height="65px" contain></v-img>
                  </v-col>
                  <v-col cols="3" class="pa-0 ma-0">
                    <v-img :src="require('@/assets/images/logo-ue-feder.jpg')" :lazy-src="require('@/assets/images/logo-ue-feder.jpg')" width="200px" height="70px" contain></v-img>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-img :src="require('@/assets/images/header-frame.png')" :lazy-src="require('@/assets/images/header-frame.png')" contain height="43vh" width="33vw">
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-img class="home-img d-sm-flex d-md-none" min-height="33vh" width="100vw" :src="require('@/assets/images/header-bg.jpg')" :lazy-src="require('@/assets/images/header-bg.jpg')">
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-container class="d-flex justify-center align-center">
              <div style="width: fit-content">
                <h1 class="title-img">Explorez l'occupation du sol de la région Grand Est !</h1>
                <br/>
                <h2 class="title-h2-img">Le référentiel OCS GE2</h2>
                <p class="pb-7 img-text">L'OCcupation du Sol à Grande Échelle de la Région Grand Est</p>
                <HomeSearch/>
                <v-row no-gutters class="mt-5 pt-2 pb-2" style="background-color: white; border-radius: 15px" justify="center" height="100px">
                  <v-col cols="2" class="pa-0 ma-0">
                    <v-img :src="require('@/assets/images/logo_geograndest_blank.png')" :lazy-src="require('@/assets/images/logo_geograndest_blank.png')" width="150px" height="65px" contain></v-img>
                  </v-col>
                  <v-col cols="2" class="pa-0 ma-0">
                    <v-img :src="require('@/assets/images/logo_prefet.png')" :lazy-src="require('@/assets/images/logo_prefet.png')" width="150px" height="65px" contain></v-img>
                  </v-col>
                  <v-col cols="3" class="pa-0 ma-0">
                    <v-img :src="require('@/assets/images/logo_grandest_blank.png')" :lazy-src="require('@/assets/images/logo_grandest_blank.png')" width="160px" height="65px" contain></v-img>
                  </v-col>
                  <v-col cols="3" class="pa-0 ma-0 ml-2">
                    <v-img :src="require('@/assets/images/ue-feder_blank.png')" :lazy-src="require('@/assets/images/ue-feder_blank.png')" width="200px" height="65px" contain></v-img>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <!-- Bandeau d'accueil de la plateforme (Version mobile) -->
    <!-- <v-img class="home-img d-sm-flex d-md-none" style="background-color: #4473c5" height="50vh" contain :src="require('@/assets/images/bandeau_acceuil_v2.jpg')" :lazy-src="require('@/assets/images/bandeau_acceuil_v2.jpg')">
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img> -->

    <!-- Charge la section "La région Grand Est en quelques chiffres" -->
    <!-- <Numbers></Numbers> -->
    <Cities></Cities>
    <DownloadSection></DownloadSection>
    <!-- Charge la section des partenaires -->
    <TableMillesime/>
    <Partners></Partners>
  </div>
</template>

<script>
import Partners from "@/components/Home/Partners";
import Cities from "@/components/Home/Cities"
import DownloadSection from "@/components/Home/DownloadSection";
import HomeSearch from "@/components/Home/HomeSearch"
import TableMillesime from "@/components/Home/TableMillesime"
export default {
  name: "Home",
  components: {
    Cities: Cities,
    HomeSearch,
    Partners: Partners,
    DownloadSection: DownloadSection,
    TableMillesime
  },
};
</script>

<style scoped>
.img-text {
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.7);
}

.title-img {
  color: white;
  font-size: 2vw;
  font-weight: inherit;
  width: fit-content;
}

.title-h2-img {
  color: white;
  font-size: 1.7vw;
  font-weight: inherit;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .img-text {
    font-size: 18px;
  }

  .title-img {
    font-size: 26px;
  }

  .title-h2-img {
    font-size: 24px;
  }
}
</style>